import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import axios from "axios";
import Loader from "../images/loader.svg";
import Breadcrumbs from "../components/Breadcrumbs/Breadcrumbs";

import { graphql } from "gatsby";

export default class GetAgreements extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      breadcrumbs: [
        {
          title: "Головна",
          url: "/",
        },
        {
          title: "Отримати договір",
        },
      ],
      errors: {
        name: {
          status: false,
          require: "Обов`язкове поле",
        },
        email: {
          status: false,
          require: "Обов`язкове поле",
          email: "Помилка пошти",
        },
        phone: {
          status: false,
          require: "Обов`язкове поле",
        },
      },
      id: 0,
      fStatus: false,
      button: false,
      phone: "",
    };

    this.form = React.createRef();
    this.sendForm = this.sendForm.bind(this);

    this.name = React.createRef();
    this.phone = React.createRef();
    this.email = React.createRef();
  }

  componentDidMount() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    if (!urlParams.get("id")) window.location.href = "404";
    this.setState({
      id: urlParams.get("id"),
    });
  }

  sendForm() {
    let status = true;
    if (!this.name.current.value) {
      status = false;
      this.setState((state) => ({
        errors: {
          ...state.errors,
          name: {
            ...state.errors.name,
            status: true,
          },
        },
      }));
      this.name.current.classList.add("error");
    }
    if (!this.phone.current.value) {
      status = false;
      this.setState((state) => ({
        errors: {
          ...state.errors,
          phone: {
            ...state.errors.phone,
            status: true,
          },
        },
      }));
      this.phone.current.classList.add("error");
    }
    const re = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
    if (
      !this.email.current.value ||
      !re.test(String(this.email.current.value).toLowerCase())
    ) {
      status = false;
      this.setState((state) => ({
        errors: {
          ...state.errors,
          email: {
            ...state.errors.email,
            status: true,
          },
        },
      }));
      this.email.current.classList.add("error");
    }
    if (!status) return;
    this.setState({ button: true });
    const data = new FormData(this.form.current);
    let out = {};
    for (let pair of data.entries()) {
      out[pair[0]] = pair[1];
    }

    axios
      .post(
        `${process.env.API_ENDPOINT}/estate/agreements/${this.state.id}/request-agreement`,
        JSON.stringify(out)
      )
      .then((response) => {
        this.setState({
          fStatus: true,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  render() {
    return (
      <Layout>
        <SEO
          title="Отримати договір"
          location={this.props.location}
          isNofollow
        />
        <div className="view-container view-get-agreements">
          <Breadcrumbs items={this.state.breadcrumbs} />
          <h1 className="view-title view-title--h1">
            Отримати <span>договір</span>
          </h1>
          <div className="view-text">
            <p>
              Щоб отримати договір, ми надішлемо Запит на публічну інформацію,
              для цього потрібно заповнити форму
            </p>
            <h2 className="view-title view-title--h2">
              Генерація запиту для отримання договору оренди
            </h2>
            <p>
              Заповнивши лише базову інформацію про вас, ви отримаєте готовий
              юридично правильний запит на отримання тексту договору оренди
            </p>
          </div>
          {!this.state.fStatus && (
            <form
              className="view-form"
              ref={this.form}
              onSubmit={(event) => {
                event.preventDefault();
                this.sendForm();
              }}
            >
              <div className="view-form__group">
                <input
                  type="text"
                  ref={this.name}
                  onChange={() => {
                    this.name.current.value &&
                      this.name.current.classList.remove("error");
                    this.name.current.value &&
                      this.setState((state) => ({
                        errors: {
                          ...state.errors,
                          name: {
                            ...state.errors.name,
                            status: false,
                          },
                        },
                      }));
                  }}
                  name="sender_name"
                  className="view-form__field"
                  placeholder="Прізвище ім`я по-батькові"
                />
                {this.state.errors.name && this.state.errors.name.status && (
                  <div className="view-form__error">
                    {this.state.errors.name.require}
                  </div>
                )}
              </div>
              <div className="view-form__group">
                <input
                  type="tel"
                  pattern="[0-9]*"
                  ref={this.phone}
                  value={this.state.phone}
                  onChange={(event) => {
                    this.setState({
                      phone: event.target.value.replace(/\D/, ""),
                    });
                    this.phone.current.value &&
                      this.phone.current.classList.remove("error");
                    this.phone.current.value &&
                      this.setState((state) => ({
                        errors: {
                          ...state.errors,
                          phone: {
                            ...state.errors.phone,
                            status: false,
                          },
                        },
                      }));
                  }}
                  name="sender_phone"
                  className="view-form__field"
                  placeholder="Контактний номер телефону"
                />
                {this.state.errors.phone && this.state.errors.phone.status && (
                  <div className="view-form__error">
                    {this.state.errors.phone.require}
                  </div>
                )}
              </div>
              <div className="view-form__group">
                <input
                  type="email"
                  ref={this.email}
                  onChange={() => {
                    this.email.current.value &&
                      this.email.current.classList.remove("error");
                    this.email.current.value &&
                      this.setState((state) => ({
                        errors: {
                          ...state.errors,
                          email: {
                            ...state.errors.email,
                            status: false,
                          },
                        },
                      }));
                  }}
                  name="sender_email"
                  className="view-form__field"
                  placeholder="Контактна елекронна скринька"
                />
                {this.state.errors.email && this.state.errors.email.status && (
                  <div className="view-form__error">
                    {this.email.current.value
                      ? this.state.errors.email.email
                      : this.state.errors.email.require +
                        ", " +
                        this.state.errors.email.email}
                  </div>
                )}
              </div>
              <input type="hidden" name="agreement_id" value={this.state.id} />
              <div className="view-form__actions">
                <button className="button button--primary">
                  {!this.state.button ? "Відправити" : <Loader />}
                </button>
              </div>
            </form>
          )}
          {this.state.fStatus && (
            <div className="view-form__success">
              <svg
                width="45"
                height="45"
                viewBox="0 0 45 45"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M22.5 45C10.0932 45 0 34.9058 0 22.5C0 10.0932 10.0932 0 22.5 0C34.9058 0 45 10.0932 45 22.5C45 34.9058 34.9058 45 22.5 45ZM22.5 2.24995C11.3345 2.24995 2.24995 11.3345 2.24995 22.5C2.24995 33.6664 11.3345 42.75 22.5 42.75C33.6664 42.75 42.75 33.6667 42.75 22.5C42.75 11.3345 33.6664 2.24995 22.5 2.24995Z"
                  fill="black"
                />
                <circle cx="22.5" cy="22.5" r="22.5" fill="#1A1F71" />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M35.1156 13.5261C35.7472 14.1033 35.7985 15.0909 35.2302 15.7317L23.4966 28.9661C22.3175 30.2962 20.2802 30.3506 19.0335 29.0851L13.5756 23.544C12.9748 22.9342 12.9748 21.9452 13.5756 21.3351C14.1763 20.7249 15.1505 20.7249 15.7515 21.3351L20.0633 25.7121C20.6867 26.3447 21.7051 26.3173 22.2948 25.6524L32.9429 13.6418C33.5111 13.001 34.484 12.9489 35.1156 13.5261Z"
                  fill="white"
                />
              </svg>
              Ваш запит успішно сформований!
            </div>
          )}
        </div>
      </Layout>
    );
  }
}

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`;
